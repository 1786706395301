import React from "react";
import { Grommet } from "grommet";
import { Route, Router, Switch } from "react-router-dom";

import { HomePage } from "src/components/pages/HomePage";

import { history } from "src/lib/history";

function App() {
  return (
    <Grommet>
      <Router history={history}>
        <Switch>
          <Route path="/" component={HomePage} />
        </Switch>
      </Router>
    </Grommet>
  );
}

export default App;
