import { useCollectionData } from "react-firebase-hooks/firestore";

import { firestore } from "src/lib/firebase";

type Video = {
  id: string;
  url: string;
  created: number;
  status: "pending" | "approved";
};

type NewVideo = Omit<Video, "id"> & {
  status: "pending";
};

enum Collections {
  Videos = "videos",
}

export const addNewVideo = (newVideo: NewVideo) => {
  return firestore
    .collection(Collections.Videos)
    .doc()
    .set(newVideo, { merge: true });
};

export const useVideos = (
  limit: 10,
  startAfter?: firebase.firestore.DocumentReference
) => {
  let query = firestore
    .collection(Collections.Videos)
    .orderBy("created", "desc")
    .limit(limit);
  if (startAfter) {
    query = query.startAfter(startAfter);
  }
  return useCollectionData<Video>(query, { idField: "id" });
};
