import React, { useState } from "react";
import { Box, Button, Heading, Layer, LayerProps, Text, TextInput } from "grommet";
import { Close } from "grommet-icons";

import { addNewVideo } from "src/lib/urm8";

const urlRegex = /https:\/\/www\.youtube\.com\/embed\/.*/;

interface AddVideoModalProps extends LayerProps {
  onClose: () => void;
}

export const AddVideoModal: React.FC<AddVideoModalProps> = ({
  onClose,
  ...props
}) => {
  const [url, setUrl] = useState<string>("");
  const [saving, setSaving] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);
  const [urlError, setUrlError] = useState<string>();

  const saveNewVideo = async () => {
    setSaving(true);
    await addNewVideo({
      url,
      status: "pending",
      created: Date.now(),
    });
    setSaving(false);
    setSaved(true);
  };

  const onUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newUrl = event.target.value;
    if (!urlRegex.test(newUrl)) {
      setUrlError("Url is malformed");
    }
    setUrl(newUrl);
  };

  const renderForm = () => (
    <Box gap="medium">
      <Box width="large">
        <TextInput
          placeholder="Video url - eg: https://www.youtube.com/embed/rLiMIrMYg1s"
          onChange={onUrlChange}
        />
      </Box>
      {urlError && <Text color="status-error">{urlError}</Text>}
      <Button
        primary
        disabled={saving || saved || !!urlError}
        onClick={saveNewVideo}
        label={<Box pad={{ vertical: "small" }}>Submit video</Box>}
      />
    </Box>
  );

  const renderThankyou = () => (
    <>
      <Text>Your video has been submitted and is pending review.</Text>
    </>
  );

  return (
    <Layer {...props}>
      <Box pad="medium">
        <Box
          direction="row"
          justify="between"
          align="center"
          margin={{ bottom: "medium" }}
        >
          <Heading size="small" margin={"none"}>
            Add A Video
          </Heading>
          <Button onClick={onClose} icon={<Close />} />
        </Box>
        {saved ? renderThankyou() : renderForm()}
      </Box>
    </Layer>
  );
};
