import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD9NDdQPNv9IrxaiS3QXRmk0vsrBafagJw",
  authDomain: "urm8-prd.firebaseapp.com",
  databaseURL: "https://urm8-prd.firebaseio.com",
  projectId: "urm8-prd",
  storageBucket: "urm8-prd.appspot.com",
  messagingSenderId: "1055834907883",
  appId: "1:1055834907883:web:7fdcda83cb876f1e9e8aae",
  measurementId: "G-5RQ4ERXTN6",
};

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();
