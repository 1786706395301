import React, { useState } from "react";
import { Box, Button, Heading, Text } from "grommet";
import { AddCircle } from "grommet-icons";

import { AddVideoModal } from "src/components/modals/AddVideoModal";

import { useVideos } from "src/lib/urm8";

export const HomePage: React.FC = () => {
  const [showAddVideo, setShowAddVideo] = useState<boolean>();
  const [lastVideo, setLastVideo] = useState<any>();
  const [videos, videosLoading, videosError] = useVideos(10, lastVideo);
  const [heroVideo, ...restVideos] = videos || [];
  return (
    <>
      <Box responsive alignSelf="center" margin={{ horizontal: "xlarge" }}>
        <Box flex direction="row" align="center" justify="between">
          <Heading>urm8</Heading>
          <Button
            primary
            onClick={() => setShowAddVideo(true)}
            label={
              <Box direction="row" pad={{ vertical: "xsmall" }}>
                <AddCircle color="white" size="medium" />
                <Text margin={{ left: "small" }}>Add A Video</Text>
              </Box>
            }
          />
        </Box>

        <Box gap="large" margin={{ bottom: "large" }}>
          {heroVideo && (
            <iframe
              allowFullScreen
              frameBorder={0}
              height="500"
              src={heroVideo.url}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          )}
          {restVideos?.map((video) => (
            <Box>
              <iframe
                allowFullScreen
                frameBorder={0}
                width="30%"
                height="200"
                src={video.url}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            </Box>
          ))}
        </Box>
      </Box>
      {showAddVideo && <AddVideoModal onClose={() => setShowAddVideo(false)} />}
    </>
  );
};
